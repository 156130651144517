import { render, staticRenderFns } from "./HVACMajor.vue?vue&type=template&id=af2267fc&scoped=true&"
import script from "./HVACMajor.vue?vue&type=script&lang=js&"
export * from "./HVACMajor.vue?vue&type=script&lang=js&"
import style0 from "./HVACMajor.vue?vue&type=style&index=0&id=af2267fc&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af2267fc",
  null
  
)

export default component.exports