import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/";
import AxiosPlugin from "@/utils/plugin/axios-plugin";
import operatorPlugin from "@/utils/plugin/operator-plugin";
import format from "vue-text-format";
import "@/permission";
import "@/core/element-ui_use";
import ElementUI from "element-ui";
import moment from "moment";
import "@/theme/element-#D1100B/index.css";
import CollapseTransition from "element-ui/lib/transitions/collapse-transition";
//表单验证插件
import rules from "./utils/validate/validate";

//引入公共输入框组件
import ComponentInput from "@/components/ComponentInput.vue";

import html2Canvas from "html2canvas";
import JsPDF from "jspdf";

import "./assets/icon/font/iconfont.css";

import MessagePlugin from "./components/phoneMessage/message.js";

Vue.use(MessagePlugin);

Vue.component("v-input", ComponentInput);
// 引用工具类
import tools from "@/utils/tools";
import BigNumber from "@/utils/bigNumber/BigNumber"; // 数值计算
Vue.prototype.$number = BigNumber;

// 引入打印插件
import Print from "@/utils/print/print";

// import Print from "vue-print-nb";
Vue.use(Print);
// 引入用公共分页组件
import Pagination from "@/components/Pagination";

Vue.component("pagination", Pagination);

Vue.prototype.$rules = rules;

Vue.prototype.$tools = tools;
Vue.config.productionTip = false;

moment.prototype.toJSON = function () {
  return moment(this).format("YYYY-MM-DD HH:mm:ss");
};

// moment格式化时间格式
Vue.prototype.timeDataFormat = function (time, format) {
  return moment(time).format(format);
};

// 关掉打印
//console.log = function () {}
console.log(
  process.env.NODE_ENV,
  "process.env.NODE_ENVprocess.env.NODE_ENVprocess.env.NODE_ENVprocess.env.NODE_ENVprocess.env.NODE_ENVprocess.env.NODE_ENVprocess.env.NODE_ENVprocess.env.NODE_ENVprocess.env.NODE_ENV"
);
Vue.prototype.$url = " https://api.zytsaas.com";

Vue.use(AxiosPlugin);
Vue.use(operatorPlugin);
Vue.use(format);
Vue.use(ElementUI);
Vue.use(CollapseTransition);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
