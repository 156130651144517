<template>
    <div class="box" style="overflow: hidden;">


        <el-row :gutter="10" v-loading="MG_ElectricityPageDataLoading">
            <el-col :span="8">
                <el-card style=" width: 100%;" class="InfoCard">
                    <el-row style="height: 35px;line-height: 35px;">
                        <el-col :span="12" style="
                     font-size: 18px; font-weight: bold
                    ">
                            <span v-if="formatCountOptionsRadioIs == 1">业态总计面积占比</span>
                            <span v-if="formatCountOptionsRadioIs == 2">业态总计数量占比</span>
                        </el-col>
                        <el-col :span="12" style="text-align: right;">
                            <el-radio-group v-model="formatCountOptionsRadioIs" size="small" @change="changeType">
                                <el-radio-button :label="1"><i class="el-icon-s-home"></i>&nbsp;面积</el-radio-button>
                                <el-radio-button :label="2"><i class="el-icon-s-grid"></i>&nbsp;数量</el-radio-button>
                            </el-radio-group>
                        </el-col>
                    </el-row>
                    <el-row style="height: 1px;background-color: #EBEEF5;margin-top: 4px;"></el-row>
                    <div style="position: relative;">
                        <div style="position: absolute;left: 10px;top: 0;font-size: 12px;font-weight: bold; ">
                            <div v-html="formatCountOptionsNumView" v-if="formatCountOptionsRadioIs == 1">
                            </div>
                            <div v-html="formatCountOptionsNumView2" v-if="formatCountOptionsRadioIs == 2">
                            </div>
                        </div>
                        <EchartsIndex ref="formatCountOptions" refId="formatCountOptions" :option="formatCountOptions"
                            @onclick="formatCountOptionsClick" styleStr="width: 100%; height: 290px"
                            v-if="this.MG_BaseInfo.FAP_Shop && this.MG_BaseInfo.FAP_Shop.length > 0" />
                        <el-empty description="暂无数据" v-else style="width: 100%; height: 290px"></el-empty>
                    </div>
                </el-card>

            </el-col>
            <el-col :span="8" v-loading="MG_ElectricityPageDataLoading">
                <el-card style=" width: 100%;" class="InfoCard">
                    <el-row style="height: 35px;line-height: 35px;">
                        <el-col :span="12">
                            <span style="font-size: 18px; font-weight: bold" v-if="TopRadio === 1">不同业态商铺的用电负荷</span>
                            <span style="font-size: 18px; font-weight: bold"
                                v-if="TopRadio === 2">不同业态商铺不同版本的商铺数量</span>
                        </el-col>
                        <el-col :span="12" style="text-align: right;">
                            <el-radio-group v-model="TopRadio" size="small" @change="changeTopRadio">
                                <el-radio-button :label="1"><i class="el-icon-s-data"></i>&nbsp;用电负荷</el-radio-button>
                                <el-radio-button :label="2"><i class="el-icon-s-data"></i>&nbsp;商铺数量</el-radio-button>
                            </el-radio-group>

                        </el-col>
                    </el-row>
                    <el-row style="height: 1px;background-color: #EBEEF5;margin-top: 4px;"></el-row>
                    <el-row v-if="TopRadio == 1">
                        <EchartsIndex refId="optionTopDataBarAll" :option="optionTopDataBarAll"
                            styleStr="width: 100%;height: 290px;"
                            v-if="MG_ElectricityPageData.LoadQuota_Electricity_All && MG_ElectricityPageData.LoadQuota_Electricity_All.length > 0" />
                        <el-empty description="暂无数据" style="width: 100%; height: 290px;" v-else></el-empty>
                    </el-row>

                    <el-row v-if="TopRadio == 2">
                        <EchartsIndex refId="optionTopDataBarCount" :option="optionTopDataBarCount"
                            styleStr="width: 100%;height: 290px;"
                            v-if="MG_ElectricityPageData.LoadQuota_Electricity_Count && MG_ElectricityPageData.LoadQuota_Electricity_Count.length > 0" />
                        <el-empty description="暂无数据" style="width: 100%; height: 290px;" v-else></el-empty>
                    </el-row>


                </el-card>
            </el-col>
            <el-col :span="8" v-loading="MG_ElectricityPageDataLoading">
                <el-card style=" width: 100%" class="InfoCard">
                    <el-row style="height: 35px;line-height: 35px;">
                        <el-col :span="24">
                            <span style="font-size: 18px; font-weight: bold">不同业态商铺的用电负荷平均指标</span>

                        </el-col>

                    </el-row>
                    <el-row style="height: 1px;background-color: #EBEEF5;margin-top: 4px;"></el-row>
                    <el-row>
                        <EchartsIndex refId="optionTopDataBar2" :option="optionTopDataBar2"
                            styleStr="width: 100%;height: 290px;"
                            v-if="MG_ElectricityPageData.LoadQuota_Electricity_Avg && MG_ElectricityPageData.LoadQuota_Electricity_Avg.length > 0" />
                        <el-empty description="暂无数据" style="width: 100%; height: 290px;" v-else></el-empty>
                    </el-row>

                </el-card>
            </el-col>

        </el-row>
        <el-row :gutter="10" style="margin-top: 5px">
            <el-col :span="24" v-loading="MG_ElectricityPageDataLoading">
                <el-card style=" width: 100%" class="InfoCard">
                    <el-row style="height: 35px;line-height: 35px;">
                        <el-col :span="24">
                            <span style="font-size: 18px; font-weight: bold">不同项目各餐饮业态商铺的用电负荷平均指标</span>

                        </el-col>

                    </el-row>
                    <el-row style="height: 1px;background-color: #EBEEF5;margin-top: 4px;"></el-row>
                    <el-row>
                        <EchartsIndex refId="optionCenterDataBar" :option="optionCenterDataBar"
                            styleStr="width: 100%;height: 290px;"
                            v-if="MG_ElectricityPageData.LoadQuota_Electricity_AllProject_Avg && MG_ElectricityPageData.LoadQuota_Electricity_AllProject_Avg.length > 0" />
                        <el-empty description="暂无数据" style="width: 100%; height: 290px;" v-else></el-empty>
                    </el-row>

                </el-card>
            </el-col>
        </el-row>
        <el-row :gutter="10" style="margin-top: 5px">

            <el-col :span="24" v-loading="MG_ElectricityPageDataLoading">
                <el-card style="width: 100%" class="InfoCard">
                    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                        <el-tab-pane v-for="item in this.MG_ElectricityPageData?.ShopVersion" :key="item" :label="item"
                            :name="item">
                        </el-tab-pane>
                    </el-tabs>
                    <div>
                        <el-row style="line-height: 35px;height: 35px;">
                            <el-col :span="16">
                                <span style="font-weight: bold;font-size: 18px;" v-if="BottomRadio === 1">
                                    不同用电负荷指标商铺的商铺数量---{{ activeName }}
                                </span>
                                <span style="font-weight: bold;font-size: 18px;" v-if="BottomRadio === 2">
                                    不同用电负荷指标商铺的商铺数量占比---{{ activeName }}
                                </span>

                            </el-col>
                            <el-col :span="8" style="text-align: right;">
                                <el-radio-group v-model="BottomRadio" size="small" @change="changeBottomRadio">

                                    <el-radio-button :label="1"><i
                                            class="el-icon-s-data"></i>&nbsp;数量统计</el-radio-button>
                                    <el-radio-button :label="2"><i
                                            class="el-icon-s-data"></i>&nbsp;占比统计</el-radio-button>

                                </el-radio-group>

                            </el-col>
                        </el-row>
                        <el-row style="height: 1px;background-color: #EBEEF5;margin-top: 4px;"></el-row>
                        <el-row v-if="BottomRadio == 1">
                            <EchartsIndex refId="optionBottomDataQuota_Count" :option="optionBottomDataQuota_Count"
                                styleStr="width: 100%;height: 290px;"
                                v-if="MG_ElectricityPageData.LoadQuota_Electricity_Quota_Count && MG_ElectricityPageData.LoadQuota_Electricity_Quota_Count.length > 0" />
                            <el-empty description="暂无数据" style="width: 100%; height: 290px;" v-else></el-empty>
                        </el-row>
                        <el-row v-if="BottomRadio == 2">
                            <EchartsIndex refId="optionBottomDataQuota_Proportion"
                                :option="optionBottomDataQuota_Proportion" styleStr="width: 100%;height: 290px;"
                                v-if="MG_ElectricityPageData.LoadQuota_Electricity_Quota_Proportion && MG_ElectricityPageData.LoadQuota_Electricity_Quota_Proportion.length > 0" />
                            <el-empty description="暂无数据" style="width: 100%; height: 290px;" v-else></el-empty>
                        </el-row>
                    </div>

                </el-card>
            </el-col>

        </el-row>
        <el-row :gutter="10" style="margin-top: 5px">

            <el-col :span="24" v-loading="MG_ElectricityPageDataLoading">
                <el-card style="width: 100%;" class="InfoCard">
                    <el-tabs v-model="activeName2" type="card" @tab-click="handleClick2">
                        <el-tab-pane v-for="(item, index) in this.MG_ElectricityPageData?.ShopVersion"
                            :key="item + index" :label="item" :name="item">
                        </el-tab-pane>
                    </el-tabs>
                    <el-row style="font-weight: bold;font-size: 18px;height: 35px;line-height: 35px;">
                        <el-col :span="16">

                            <span v-if="BottomRadio2 === 1">不同面积区间的用电负荷量---{{ activeName2 }}</span>
                            <span v-if="BottomRadio2 === 2">不同面积区间的用电负荷平均指标---{{ activeName2 }}</span>
                            <span v-if="BottomRadio2 === 3">不同面积区间的商铺数量---{{ activeName2 }}</span>
                        </el-col>
                        <el-col :span="8" style="text-align: right;">
                            <el-radio-group v-model="BottomRadio2" size="small" @change="changeBottomRadio2">

                                <el-radio-button :label="1"><i class="el-icon-s-data"></i>&nbsp;用电负荷</el-radio-button>
                                <el-radio-button :label="2"><i class="el-icon-s-data"></i>&nbsp;平均指标</el-radio-button>
                                <el-radio-button :label="3"><i class="el-icon-s-data"></i>&nbsp;商铺数量</el-radio-button>
                            </el-radio-group>

                        </el-col>
                    </el-row>
                    <el-row style="height: 1px;background-color: #EBEEF5;margin-top: 4px;"></el-row>

                    <el-row v-if="BottomRadio2 == 1">
                        <EchartsIndex refId="optionBottomDataAll" :option="optionBottomDataAll"
                            styleStr="width: 100%;height: 290px;"
                            v-if="MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_All && MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_All.length > 0" />
                        <el-empty description="暂无数据" style="width: 100%; height: 290px;" v-else></el-empty>
                    </el-row>

                    <el-row v-if="BottomRadio2 == 2">
                        <EchartsIndex refId="optionBottomDataAvg" :option="optionBottomDataAvg"
                            styleStr="width: 100%;height: 290px;"
                            v-if="MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_Avg && MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_Avg.length > 0" />
                        <el-empty description="暂无数据" style="width: 100%; height: 290px;" v-else></el-empty>
                    </el-row>
                    <el-row v-if="BottomRadio2 == 3">
                        <EchartsIndex refId="optionBottomDataCount" :option="optionBottomDataCount"
                            styleStr="width: 100%;height: 290px;"
                            v-if="MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_Count && MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_Count.length > 0" />
                        <el-empty description="暂无数据" style="width: 100%; height: 290px;" v-else></el-empty>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>

    </div>
</template>

<script>
// 引入echarts图表组件
import EchartsIndex from "@/components/Echarts/index";

export default {
    name: "MG_CateringDataPage", // 餐饮数据模块
    components: {
        EchartsIndex,
    },
    props: {
        typeList: {
            type: Array,
            default: () => [],
        },
        projectId: {
            type: String,
            default: "",
        },
        homeData: {
            type: Object,
            default: () => { },
        },
    },
    data() {
        return {
            activeName: "",
            activeName2: "",
            MG_BaseInfo: {},
            formatCountInfoArr: [],
            formatCountOptionsRadioIs: 1,
            formatCountOptionsRadio: 1,
            // 业态数量占比数据
            formatCountOptions: {
                tooltip: {},

                label: {
                },
                series: [
                    {
                        type: 'sunburst',
                        center: ['50%', '50%'],
                        // radius: [0, '80%'],
                        data: [],

                        label: {
                            rotate: 'radial',

                        },
                        clockwise: true,
                        itemStyle: {
                            borderRadius: 8,
                            borderWidth: 2
                        },
                        levels: [
                            {
                                // rotate: 0,
                                radius: [0, '10%'],
                                label: {
                                    rotate: 0,
                                    // formatter: (a) => {
                                    //   return a.name +
                                    //     '\n\n' + a.value.toFixed(2) + '㎡'
                                    // },
                                },
                            },
                            {

                                radius: ['25%', '50%'],
                                label: {
                                    rotate: 0,
                                },
                                tooltip: {
                                    //tip提示框
                                    trigger: "item",
                                    // formatter: "{a} <br/>{b} : {c} ",
                                    formatter: (a) => {
                                        let num = (a.value / a.treePathInfo[0].value * 100).toFixed(2)
                                        return a.name + " : " + num + "%"
                                    }

                                },
                            },
                            {
                                radius: ['55%', '80%'],
                                label: {
                                    rotate: 0,
                                    position: 'outside'
                                }
                            },

                        ]
                    }
                ]

            },
            TopRadio: 1,
            TopRadio2: 1,
            BottomRadio: 1,
            BottomRadio2: 1,
            MG_ElectricityPageData: {

            },
            MG_ElectricityPageDataLoading: false,

            optionTopDataBarAll: {
                grid: {
                    top: 50, // 设置图表距离容器顶部的距离为10%\
                    left: 100,
                    right: 40,
                    bottom: 30
                    // 其他grid属性...
                },
                label: {
                    show: true,
                    position: "top",
                    formatter: (val) => {

                        if (val.value) {
                            return val.value
                        } else {
                            return ''
                        }
                    }

                },
                legend: {
                    //图例系列
                    show: true,
                    top: 5,
                    itemWidth: 14,
                    itemHeight: 14,
                    data: [],
                },
                tooltip: {
                    trigger: "item",
                    formatter: `{a} : {c}kW`
                },
                xAxis: {

                    type: "category",
                    data: [],
                    axisLabel: {
                        // 坐标轴刻度标签的相关设置。
                        show: true,
                        interval: 0,
                        //rotate: "-45",
                    },
                },
                yAxis: {
                    axisLabel: {
                        formatter: "{value} kW",
                    },
                    name: "电负荷量（kW）",
                    nameLocation: "end",
                    nameTextStyle: {
                        width: 200,
                        align: "right",
                    },
                    axisLine: {
                        //坐标轴轴线
                        show: true,
                    },
                    axisTick: {
                        //坐标轴刻度
                        show: true,
                    },
                    type: "value",
                },
                series: [


                ],
            },
            optionCenterDataBar: {
                grid: {
                    top: 60, // 设置图表距离容器顶部的距离为10%\
                    left: 120,
                    right: 70,
                    bottom: 60
                    // 其他grid属性...
                },
                label: {
                    show: true,
                    position: "top",
                    formatter: (val) => {

                        if (val.value) {
                            return Number(val.value).toFixed(2)
                        } else {
                            return ''
                        }
                    }
                },
                legend: {
                    //图例系列
                    show: true,
                    top: 5,
                    itemWidth: 14,
                    itemHeight: 14,
                    data: [],
                },
                tooltip: {
                    trigger: "item",
                    formatter: (val) => {
                        return `${val.name}：${Number(val.value).toFixed(2)}W/㎡`
                    }
                },
                xAxis: {

                    type: "category",
                    data: [],
                    axisLabel: {
                        // 坐标轴刻度标签的相关设置。
                        show: true,
                        interval: 0,
                        // rotate: "-45",
                        formatter: (val) => {
                            let num = Math.ceil(val.length / 8)
                            let str = ''
                            for (let i = 0; i < num; i++) {
                                str = str + val.slice(i * 8, (i + 1) * 8) + '\n'
                            }
                            return str
                        },
                    },
                },
                yAxis: {

                    axisLabel: {
                        formatter: "{value} W/㎡",
                    },
                    name: "平均指标（W/㎡）",
                    nameLocation: "end",
                    nameTextStyle: {
                        width: 200,
                        align: "right",
                    },
                    axisLine: {
                        //坐标轴轴线
                        show: true,
                    },
                    axisTick: {
                        //坐标轴刻度
                        show: true,
                    },
                    type: "value",
                },
                series: [


                ],
            },
            optionTopDataBarCount: {
                grid: {
                    top: 50, // 设置图表距离容器顶部的距离为10%\
                    left: 100,
                    right: 40,
                    bottom: 30
                    // 其他grid属性...
                },
                label: {
                    show: true,
                    position: "top",
                    formatter: (val) => {

                        if (val.value) {
                            return val.value
                        } else {
                            return ''
                        }
                    }

                },
                legend: {
                    //图例系列
                    show: true,
                    top: 5,
                    itemWidth: 14,
                    itemHeight: 14,
                    data: [],
                },
                tooltip: {
                    trigger: "item",
                    formatter: `{a} : {c}个`
                },
                xAxis: {

                    type: "category",
                    data: [],
                    axisLabel: {
                        // 坐标轴刻度标签的相关设置。
                        show: true,
                        interval: 0,
                        //rotate: "-45",
                    },
                },
                yAxis: {
                    axisLabel: {
                        formatter: "{value} 个",
                    },
                    name: "商铺数量（个）",
                    nameLocation: "end",
                    nameTextStyle: {
                        width: 200,
                        align: "right",
                    },
                    axisLine: {
                        //坐标轴轴线
                        show: true,
                    },
                    axisTick: {
                        //坐标轴刻度
                        show: true,
                    },
                    type: "value",
                },
                series: [


                ],
            },
            optionTopDataBar2: {
                grid: {
                    top: 50, // 设置图表距离容器顶部的距离为10%\
                    left: 100,
                    right: 40,
                    bottom: 30
                    // 其他grid属性...
                },
                label: {
                    show: true,
                    position: "top",
                    formatter: (val) => {

                        if (val.value) {
                            return Number(val.value).toFixed(2)
                        } else {
                            return ''

                        }
                    },
                },

                legend: {
                    //图例系列
                    show: true,
                    top: 5,
                    itemWidth: 14,
                    itemHeight: 14,
                    data: [],
                },
                tooltip: {
                    trigger: "item",
                    formatter: (val) => {

                        return `${val.seriesName}：${Number(val.value).toFixed(2)}W/㎡`
                    }
                },
                xAxis: {

                    type: "category",
                    data: [],
                    axisLabel: {
                        // 坐标轴刻度标签的相关设置。
                        show: true,
                        interval: 0,
                        //rotate: "-45",
                    },
                },
                yAxis: {
                    axisLabel: {
                        formatter: "{value} W/㎡",
                    },
                    name: "平均指标（W/㎡）",
                    nameLocation: "end",
                    nameTextStyle: {
                        width: 200,
                        align: "right",
                    },
                    axisLine: {
                        //坐标轴轴线
                        show: true,
                    },
                    axisTick: {
                        //坐标轴刻度
                        show: true,
                    },
                    type: "value",
                },
                series: [


                ],
            },

            optionBottomDataQuota_Count: {
                grid: {
                    top: 60, // 设置图表距离容器顶部的距离为10%\
                    left: 120,
                    right: 70,
                    bottom: 30
                    // 其他grid属性...
                },
                label: {
                    show: true,
                    position: "top",
                    formatter: (val) => {

                        if (val.value) {
                            return val.value
                        } else {
                            return ''
                        }
                    }
                },
                legend: {
                    //图例系列
                    show: true,
                    top: 5,
                    itemWidth: 14,
                    itemHeight: 14,
                    data: [],
                },
                tooltip: {
                    trigger: "item",
                    formatter: `{a} : {c}个`
                },
                xAxis: {

                    type: "category",
                    data: [],
                    axisLabel: {
                        // 坐标轴刻度标签的相关设置。
                        show: true,
                        interval: 0,
                        // rotate: "-20",

                    },

                },
                yAxis: {
                    axisLabel: {
                        formatter: "{value} 个",
                    },
                    name: "商铺数量（个）",
                    nameLocation: "end",
                    nameTextStyle: {
                        width: 200,
                        align: "right",
                    },
                    axisLine: {
                        //坐标轴轴线
                        show: true,
                    },
                    axisTick: {
                        //坐标轴刻度
                        show: true,
                    },
                    type: "value",
                },
                series: [


                ],
            },
            optionBottomDataQuota_Proportion: {
                grid: {
                    top: 60, // 设置图表距离容器顶部的距离为10%\
                    left: 120,
                    right: 70,
                    bottom: 30
                    // 其他grid属性...
                },
                label: {
                    show: true,
                    position: "top",
                    formatter: (val) => {

                        if (val.value) {
                            return Number(val.value).toFixed(2) + '%'
                        } else {
                            return ''
                        }
                    }
                },
                legend: {
                    //图例系列
                    show: true,
                    top: 5,
                    itemWidth: 14,
                    itemHeight: 14,
                    data: [],
                },
                tooltip: {
                    trigger: "item",
                    formatter: (val) => {

                        return `${val.seriesName}：${Number(val.value).toFixed(2)}%`
                    }
                },
                xAxis: {

                    type: "category",
                    data: [],
                    axisLabel: {
                        // 坐标轴刻度标签的相关设置。
                        show: true,
                        interval: 0,
                        // rotate: "-20",

                    },

                },
                yAxis: {
                    axisLabel: {
                        formatter: "{value} %",
                    },
                    min: 0,
                    max: 100,

                    name: "占比统计（%）",
                    nameLocation: "end",
                    nameTextStyle: {
                        width: 200,
                        align: "right",
                    },
                    axisLine: {
                        //坐标轴轴线
                        show: true,
                    },
                    axisTick: {
                        //坐标轴刻度
                        show: true,
                    },
                    type: "value",
                },
                series: [


                ],
            },
            optionBottomDataCount: {
                grid: {
                    top: 60, // 设置图表距离容器顶部的距离为10%\
                    left: 120,
                    right: 70,
                    bottom: 30
                    // 其他grid属性...
                },
                label: {
                    show: true,
                    position: "top",
                    formatter: (val) => {

                        if (val.value) {
                            return val.value
                        } else {
                            return ''
                        }
                    }
                },
                legend: {
                    //图例系列
                    show: true,
                    top: 5,
                    itemWidth: 14,
                    itemHeight: 14,
                    data: [],
                },
                tooltip: {
                    trigger: "item",
                    formatter: `{a} : {c}个`
                },
                xAxis: {

                    type: "category",
                    data: [],
                    axisLabel: {
                        // 坐标轴刻度标签的相关设置。
                        show: true,
                        interval: 0,
                        //rotate: "-45",
                    },
                },
                yAxis: {
                    axisLabel: {
                        formatter: "{value} 个",
                    },
                    name: "商铺数量（个）",
                    nameLocation: "end",
                    nameTextStyle: {
                        width: 200,
                        align: "right",
                    },
                    axisLine: {
                        //坐标轴轴线
                        show: true,
                    },
                    axisTick: {
                        //坐标轴刻度
                        show: true,
                    },
                    type: "value",
                },
                series: [


                ],
            },
            optionBottomDataAll: {
                grid: {
                    top: 60, // 设置图表距离容器顶部的距离为10%\
                    left: 120,
                    right: 70,
                    bottom: 30
                    // 其他grid属性...
                },
                label: {
                    show: true,
                    position: "top",
                    formatter: (val) => {

                        if (val.value) {
                            return val.value
                        } else {
                            return ''
                        }
                    }
                },
                legend: {
                    //图例系列
                    show: true,
                    top: 5,
                    itemWidth: 14,
                    itemHeight: 14,
                    data: [],
                },
                tooltip: {
                    trigger: "item",
                    formatter: `{a} : {c}kW`
                },
                xAxis: {

                    type: "category",
                    data: [],
                    axisLabel: {
                        // 坐标轴刻度标签的相关设置。
                        show: true,
                        interval: 0,
                        //rotate: "-45",
                    },
                },
                yAxis: {
                    axisLabel: {
                        formatter: "{value} kW",
                    },
                    name: "电负荷量（kW）",
                    nameLocation: "end",
                    nameTextStyle: {
                        width: 200,
                        align: "right",
                    },
                    axisLine: {
                        //坐标轴轴线
                        show: true,
                    },
                    axisTick: {
                        //坐标轴刻度
                        show: true,
                    },
                    type: "value",
                },
                series: [


                ],
            },
            optionBottomDataAvg: {
                grid: {
                    top: 60, // 设置图表距离容器顶部的距离为10%\
                    left: 120,
                    right: 70,
                    bottom: 30
                    // 其他grid属性...
                },
                label: {
                    show: true,
                    position: "top",
                    formatter: (val) => {

                        if (val.value) {
                            return Number(val.value).toFixed(2)
                        } else {
                            return ''
                        }
                    }
                },
                legend: {
                    //图例系列
                    show: true,
                    top: 5,
                    itemWidth: 14,
                    itemHeight: 14,
                    data: [],
                },
                tooltip: {
                    trigger: "item",
                    formatter: (val) => {
                        return `${val.name}：${Number(val.value).toFixed(2)}W/㎡`
                    }
                },
                xAxis: {

                    type: "category",
                    data: [],
                    axisLabel: {
                        // 坐标轴刻度标签的相关设置。
                        show: true,
                        interval: 0,
                        //rotate: "-45",
                    },
                },
                yAxis: {
                    axisLabel: {
                        formatter: "{value} W/㎡",
                    },
                    name: "平均指标（W/㎡）",
                    nameLocation: "end",
                    nameTextStyle: {
                        width: 200,
                        align: "right",
                    },
                    axisLine: {
                        //坐标轴轴线
                        show: true,
                    },
                    axisTick: {
                        //坐标轴刻度
                        show: true,
                    },
                    type: "value",
                },
                series: [


                ],
            },

        };
    },
    computed: {

        formatCountOptionsNumView2() {
            let arr = this.formatCountInfoArr
            if (arr.length == 1) {
                return `<span>${arr[0].name}</span>：<span style="color:#D1100B">${arr[0].value || 0}</span>`
            }
            if (arr.length == 2) {
                let num = (arr[1].value / arr[0].value * 100).toFixed(2)
                return `${arr[0].name}：<span style="color:#D1100B">${arr[0].value || 0}</span></br>${arr[1].name}：<span style="color:#D1100B">${arr[1].value}</span><br/>${arr[0].name}占比：<span style="color:#D1100B">${num}%</span>`
            }
            if (arr.length == 3) {
                let num = (arr[2].value / arr[0].value * 100).toFixed(2)
                let num2 = (arr[2].value / arr[1].value * 100).toFixed(2)
                return `${arr[0].name}：<span style="color:#D1100B">${arr[0].value || 0}</span></br>${arr[1].name}>${arr[2].name}：<span style="color:#D1100B">${arr[2].value}</span><br/>${arr[0].name}占比：<span style="color:#D1100B">${num}%</span><br/>${arr[1].name}占比：<span style="color:#D1100B">${num2}%</span>`
            }
            if (arr.length == 4) {
                let num = (arr[3].value / arr[0].value * 100).toFixed(2)
                let num2 = (arr[3].value / arr[1].value * 100).toFixed(2)
                let num3 = (arr[3].value / arr[2].value * 100).toFixed(2)
                return `${arr[0].name}：<span style="color:#D1100B">${arr[0].value || 0}</span></br>${arr[1].name}>${arr[2].name}>${arr[3].name}：<span style="color:#D1100B">${arr[3].value}</span><br/>${arr[0].name}占比：<span style="color:#D1100B">${num}%</span><br/>${arr[1].name}占比：<span style="color:#D1100B">${num2}%</span><br/>${arr[2].name}占比：<span style="color:#D1100B">${num3}%</span>`
            }
            return ''
        },
        formatCountOptionsNumView() {
            let arr = this.formatCountInfoArr
            if (arr.length == 1) {
                return `<span>${arr[0].name}</span>：<span style="color:#D1100B">${arr[0].value?.toFixed(2) + '㎡' || '0㎡'}</span>`
            }
            if (arr.length == 2) {
                let num = (arr[1].value / arr[0].value * 100).toFixed(2)
                return `${arr[0].name}：<span style="color:#D1100B">${arr[0].value?.toFixed(2) + '㎡' || '0㎡'}</span></br>${arr[1].name}：<span style="color:#D1100B">${arr[1].value?.toFixed(2) + '㎡' || '0㎡'}</span><br/>${arr[0].name}占比：<span style="color:#D1100B">${num}%</span>`
            }
            if (arr.length == 3) {
                let num = (arr[2].value / arr[0].value * 100).toFixed(2)
                let num2 = (arr[2].value / arr[1].value * 100).toFixed(2)
                return `${arr[0].name}：<span style="color:#D1100B">${arr[0].value?.toFixed(2) + '㎡' || '0㎡'}</span></br>${arr[1].name}>${arr[2].name}：<span style="color:#D1100B">${arr[2].value?.toFixed(2) + '㎡' || '0㎡'}</span><br/>${arr[0].name}占比：<span style="color:#D1100B">${num}%</span><br/>${arr[1].name}占比：<span style="color:#D1100B">${num2}%</span>`
            }
            if (arr.length == 4) {
                let num = (arr[3].value / arr[0].value * 100).toFixed(2)
                let num2 = (arr[3].value / arr[1].value * 100).toFixed(2)
                let num3 = (arr[3].value / arr[2].value * 100).toFixed(2)
                return `${arr[0].name}：<span style="color:#D1100B">${arr[0].value?.toFixed(2) + '㎡' || '0㎡'}</span></br>${arr[1].name}>${arr[2].name}>${arr[3].name}：<span style="color:#D1100B">${arr[3].value?.toFixed(2) + '㎡' || '0㎡'}</span><br/>${arr[0].name}占比：<span style="color:#D1100B">${num}%</span><br/>${arr[1].name}占比：<span style="color:#D1100B">${num2}%</span><br/>${arr[2].name}占比：<span style="color:#D1100B">${num3}%</span>`
            }
            return ''
        },
    },
    mounted() {

    },
    created() {


        this.getPageData();
    },
    methods: {
        handleClick() {
            this.BottomRadio = 1
            this.changeBottomRadio()
        },
        handleClick2() {
            this.BottomRadio2 = 1
            this.changeBottomRadio2()
        },
        changeoptionCenterDataBar() {
            if (this.MG_ElectricityPageData.LoadQuota_Electricity_AllProject_Avg && this.MG_ElectricityPageData.LoadQuota_Electricity_AllProject_Avg.length > 0) {
                this.optionCenterDataBar.legend.data = []
                this.optionCenterDataBar.series = []
                this.optionCenterDataBar.xAxis.data = []
                this.MG_ElectricityPageData.LoadQuota_Electricity_AllProject_Avg.forEach((item, index) => {
                    if (index === 0) {
                        item.value.forEach(val => {
                            this.optionCenterDataBar.xAxis.data.push(val.name)
                        })

                    }
                    this.optionCenterDataBar.legend.data.push(item.name);
                    this.optionCenterDataBar.series.push({
                        name: item.name,
                        data: item.value,
                        type: "bar",

                        showBackground: true,
                        barGap: '100%',
                        emphasis: {
                            focus: "series",
                        },
                        selectedMode: 'series',
                        // barWidth: 20,

                    })
                })
            }
        },
        formatCountOptionsClick(a) {
            if (this.formatCountOptionsRadioIs === 1) {
                if (a.name === '商铺总面积') {
                    this.formatCountInfoArr.pop()
                } else {
                    this.formatCountInfoArr = [...a.treePathInfo]
                }
            } else if (this.formatCountOptionsRadioIs === 2) {

                if (a.name === '商铺总数量') {
                    this.formatCountInfoArr.pop()
                } else {
                    this.formatCountInfoArr = [...a.treePathInfo]
                }
            }

        },
        changeType() {
            if (this.formatCountOptionsRadioIs === 1) {
                let arr = []
                let colorList = ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc']
                this.MG_BaseInfo.FAP_Shop.forEach(item => {
                    let num = 0
                    item.shopAreaList.forEach((val, index) => {
                        // val.itemStyle = {
                        //     color: colorList[index > colorList.length - 1 ? index - colorList.length - 1 : index]
                        // }
                        num = val.value + num
                    })

                    let obj = {
                        name: item.formatCalss,
                        value: item.shopArea ? item.shopArea : num ? Number(num.toFixed(2)) : 0,
                        children: [...item.shopAreaList]
                    }
                    arr.push(obj)
                })
                this.formatCountOptions.series[0].data = arr;
                this.formatCountOptions.series[0].name = '商铺总面积';
                this.formatCountOptions.series[0].levels[0].label = {
                    rotate: 0,
                    formatter: (a) => {
                        return a.name +
                            '\n\n' + a.value.toFixed(2) + '㎡'
                    },
                }
                this.formatCountOptions.label = {
                    show: true,
                    rotate: 'radial',
                    formatter: (a) => {
                        return a.name +
                            '\n' + a.value.toFixed(2) + '㎡'
                    },

                }
                this.formatCountOptions.tooltip = {
                    //tip提示框
                    trigger: "item",

                    formatter: (a) => {

                        if (a.treePathInfo.length == 1) {
                            return `${a.name}：${a.value ? a.value.toFixed(2) : 0}㎡`
                        }

                        if (a.treePathInfo.length == 2) {
                            let num = (a.value / a.treePathInfo[0].value * 100).toFixed(2)
                            return `${a.name}：${a.value + '㎡'}<br/>${a.treePathInfo[0].name}占比：${num}%`
                        }
                        if (a.treePathInfo.length == 3) {
                            let num = (a.value / a.treePathInfo[0].value * 100).toFixed(2)
                            let num2 = (a.value / a.treePathInfo[1].value * 100).toFixed(2)
                            return `${a.name}：${a.value + '㎡'}<br/>${a.treePathInfo[0].name}占比：${num}%<br/>${a.treePathInfo[1].name}占比：${num2}%`
                        }

                    }

                }
                let num = 0
                this.MG_BaseInfo.FAP_Shop.forEach(item => {
                    num = num + item.shopArea
                })
                this.formatCountInfoArr = [{
                    name: '商铺总面积',
                    value: num
                }]
            } else if (this.formatCountOptionsRadioIs === 2) {
                let arr = []
                let colorList = ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc']
                this.MG_BaseInfo.FAP_Shop.forEach(item => {
                    let num = 0
                    item.shopCountList.forEach((val, index) => {
                        // val.itemStyle = {
                        //     color: colorList[index > colorList.length - 1 ? index - colorList.length - 1 : index]
                        // }
                        num = val.value + num
                    })
                    let obj = {
                        name: item.formatCalss,
                        value: item.shopCount ? item.shopCount : num ? Number(num.toFixed(2)) : 0,
                        children: [...item.shopCountList]
                    }
                    arr.push(obj)
                })
                this.formatCountOptions.series[0].name = '商铺总数量';
                this.formatCountOptions.series[0].data = arr;
                this.formatCountOptions.label = {
                    show: true,
                    rotate: 'radial',
                    formatter: `{b}\n{c}`
                }
                this.formatCountOptions.series[0].levels[0].label = {
                    rotate: 0,
                    formatter: (a) => {
                        return a.name +
                            '\n\n' + a.value
                    },
                }
                this.formatCountOptions.tooltip = {
                    //tip提示框
                    trigger: "item",

                    formatter: (a) => {

                        if (a.treePathInfo.length == 1) {
                            return `${a.name}：${a.value ? a.value.toFixed(2) : 0}`
                        }
                        if (a.treePathInfo.length == 2) {
                            let num = (a.value / a.treePathInfo[0].value * 100).toFixed(2)
                            return `${a.name}:${a.value}<br/>${a.treePathInfo[0].name}占比：${num}%`
                        }
                        if (a.treePathInfo.length == 3) {
                            let num = (a.value / a.treePathInfo[0].value * 100).toFixed(2)
                            let num2 = (a.value / a.treePathInfo[1].value * 100).toFixed(2)
                            return `${a.name}：${a.value}<br/>${a.treePathInfo[0].name}占比：${num}%<br/>${a.treePathInfo[1].name}占比：${num2}%`
                        }

                    }

                }
                let num = 0
                this.MG_BaseInfo.FAP_Shop.forEach(item => {
                    num = num + item.shopCount
                })
                this.formatCountInfoArr = [{
                    name: '商铺总数量',
                    value: num
                }]
            }
        },
        changeBottomRadio() {
            if (this.MG_ElectricityPageData.LoadQuota_Electricity_Quota_Count && this.MG_ElectricityPageData.LoadQuota_Electricity_Quota_Count.length > 0) {
                this.optionBottomDataQuota_Count.legend.data = []
                this.optionBottomDataQuota_Count.series = []
                this.optionBottomDataQuota_Count.xAxis.data = []



                this.MG_ElectricityPageData.LoadQuota_Electricity_Quota_Count.forEach((item) => {

                    if (item.name === this.activeName) {
                        item.value.forEach((val, index) => {
                            if (index === 0) {
                                val.value.forEach(el => {
                                    this.optionBottomDataQuota_Count.xAxis.data.push(el.name)
                                })

                            }
                            this.optionBottomDataQuota_Count.legend.data.push(val.name);
                            this.optionBottomDataQuota_Count.series.push({
                                name: val.name,
                                data: val.value,
                                type: "bar",

                                showBackground: true,
                                barGap: '100%',
                                emphasis: {
                                    focus: "series",
                                },
                                selectedMode: 'series',
                                // barWidth: 20,

                            })
                        })

                    }
                })
            }

            if (this.MG_ElectricityPageData.LoadQuota_Electricity_Quota_Proportion && this.MG_ElectricityPageData.LoadQuota_Electricity_Quota_Proportion.length > 0) {
                this.optionBottomDataQuota_Proportion.legend.data = []
                this.optionBottomDataQuota_Proportion.series = []
                this.optionBottomDataQuota_Proportion.xAxis.data = []
                this.MG_ElectricityPageData.LoadQuota_Electricity_Quota_Proportion.forEach((item, index) => {

                    if (item.name === this.activeName) {
                        item.value.forEach((val, index) => {
                            if (index === 0) {
                                val.value.forEach(el => {
                                    this.optionBottomDataQuota_Proportion.xAxis.data.push(el.name)
                                })
                            }
                            this.optionBottomDataQuota_Proportion.legend.data.push(val.name);
                            this.optionBottomDataQuota_Proportion.series.push({
                                name: val.name,
                                data: val.value,
                                type: "bar",

                                showBackground: true,
                                barGap: '100%',
                                emphasis: {
                                    focus: "series",
                                },
                                selectedMode: 'series',
                                // barWidth: 20,

                            })
                        })

                    }

                })
            }

        },
        changeBottomRadio2() {
            if (this.BottomRadio2 === 1) {
                if (this.MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_All && this.MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_All.length > 0) {
                    this.optionBottomDataAll.legend.data = []
                    this.optionBottomDataAll.series = []
                    this.optionBottomDataAll.xAxis.data = []

                    this.MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_All.forEach((item,) => {


                        if (item.name === this.activeName2) {
                            item.value.forEach((val, index) => {
                                if (index === 0) {
                                    val.value.forEach(el => {
                                        this.optionBottomDataAll.xAxis.data.push(el.name)
                                    })

                                }
                                this.optionBottomDataAll.legend.data.push(val.name);
                                this.optionBottomDataAll.series.push({
                                    name: val.name,
                                    data: val.value,
                                    type: "bar",

                                    showBackground: true,
                                    barGap: '100%',
                                    emphasis: {
                                        focus: "series",
                                    },
                                    selectedMode: 'series',
                                    // barWidth: 20,

                                })
                            })

                        }

                    })
                }
            } else if (this.BottomRadio2 === 2) {
                if (this.MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_Avg && this.MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_Avg.length > 0) {
                    this.optionBottomDataAvg.legend.data = []
                    this.optionBottomDataAvg.series = []
                    this.optionBottomDataAvg.xAxis.data = []
                    this.MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_Avg.forEach((item) => {
                        if (item.name === this.activeName2) {
                            item.value.forEach((val, index) => {
                                if (index === 0) {
                                    val.value.forEach(el => {
                                        this.optionBottomDataAvg.xAxis.data.push(el.name)
                                    })

                                }
                                this.optionBottomDataAvg.legend.data.push(val.name);

                                this.optionBottomDataAvg.series.push({
                                    name: val.name,
                                    data: val.value,
                                    type: "bar",
                                    // stack: item.name,
                                    showBackground: true,
                                    barGap: '100%',
                                    emphasis: {
                                        focus: "series",
                                    },
                                    selectedMode: 'series',
                                    // barWidth: 20,

                                })
                            })
                        }


                    })
                }
            } else if (this.BottomRadio2 === 3) {
                if (this.MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_Count && this.MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_Count.length > 0) {
                    this.optionBottomDataCount.legend.data = []
                    this.optionBottomDataCount.series = []
                    this.optionBottomDataCount.xAxis.data = []
                    this.MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_Count.forEach((item) => {
                        if (item.name === this.activeName2) {
                            item.value.forEach((val, index) => {
                                if (index === 0) {
                                    val.value.forEach(el => {
                                        this.optionBottomDataCount.xAxis.data.push(el.name)
                                    })

                                }
                                this.optionBottomDataCount.legend.data.push(val.name);

                                this.optionBottomDataCount.series.push({
                                    name: val.name,
                                    data: val.value,
                                    type: "bar",
                                    // stack: item.name,
                                    showBackground: true,
                                    barGap: '100%',
                                    emphasis: {
                                        focus: "series",
                                    },
                                    selectedMode: 'series',
                                    // barWidth: 20,

                                })
                            })
                        }



                    })
                }
            }

        },
        changeTopRadio() {
            if (this.TopRadio === 1) {
                if (this.MG_ElectricityPageData.LoadQuota_Electricity_All && this.MG_ElectricityPageData.LoadQuota_Electricity_All.length > 0) {
                    this.optionTopDataBarAll.legend.data = []
                    this.optionTopDataBarAll.series = []
                    this.optionTopDataBarAll.xAxis.data = []
                    this.MG_ElectricityPageData.LoadQuota_Electricity_All.forEach((item, index) => {
                        if (index === 0) {
                            item.value.forEach(val => {
                                this.optionTopDataBarAll.xAxis.data.push(val.name)
                            })

                        }
                        this.optionTopDataBarAll.legend.data.push(item.name);
                        this.optionTopDataBarAll.series.push({
                            name: item.name,
                            data: item.value,
                            type: "bar",

                            showBackground: true,
                            barGap: '100%',
                            emphasis: {
                                focus: "series",
                            },
                            selectedMode: 'series',
                            // barWidth: 20,

                        })
                    })
                }
            } else if (this.TopRadio === 2) {
                if (this.MG_ElectricityPageData.LoadQuota_Electricity_Count && this.MG_ElectricityPageData.LoadQuota_Electricity_Count.length > 0) {
                    this.optionTopDataBarCount.legend.data = []
                    this.optionTopDataBarCount.series = []
                    this.optionTopDataBarCount.xAxis.data = []
                    this.MG_ElectricityPageData.LoadQuota_Electricity_Count.forEach((item, index) => {
                        if (index === 0) {
                            item.value.forEach(val => {
                                this.optionTopDataBarCount.xAxis.data.push(val.name)
                            })

                        }
                        this.optionTopDataBarCount.legend.data.push(item.name);
                        this.optionTopDataBarCount.series.push({
                            name: item.name,
                            data: item.value,
                            type: "bar",

                            showBackground: true,
                            barGap: '100%',
                            emphasis: {
                                focus: "series",
                            },
                            selectedMode: 'series',
                            // barWidth: 20,

                        })
                    })
                }
            }

        },
        changeTopRadio2() {
            if (this.MG_ElectricityPageData.LoadQuota_Electricity_Avg && this.MG_ElectricityPageData.LoadQuota_Electricity_Avg.length > 0) {
                this.optionTopDataBar2.legend.data = []
                this.optionTopDataBar2.series = []
                this.optionTopDataBar2.xAxis.data = []
                this.MG_ElectricityPageData.LoadQuota_Electricity_Avg.forEach((item, index) => {
                    if (index === 0) {
                        item.value.forEach(val => {
                            this.optionTopDataBar2.xAxis.data.push(val.name)
                        })

                    }
                    this.optionTopDataBar2.legend.data.push(item.name);

                    this.optionTopDataBar2.series.push({
                        name: item.name,
                        data: item.value,
                        type: "bar",

                        showBackground: true,
                        barGap: '100%',
                        emphasis: {
                            focus: "series",
                        },
                        selectedMode: 'series',
                        // barWidth: 20,

                    })
                })
            }
        },
        getPageData() {
            this.MG_ElectricityPageDataLoading = true
            this.$http
                .post('/Base_ProjectManage/Project_DataAnalysis/GetMG_CateringReturnData_HR_Group', []).then(res => {
                    this.MG_ElectricityPageDataLoading = false
                    if (res.Success) {
                        this.$set(this.MG_BaseInfo, 'FAP_Shop', res.Data.FAP_Shop || {})
                        this.MG_ElectricityPageData = res.Data || {}
                        this.activeName = res.Data.ShopVersion[0] || ''
                        this.activeName2 = res.Data.ShopVersion[0] || ''
                        this.changeType()
                        this.changeoptionCenterDataBar()
                        // this.MG_ElectricityPageData = res.Data || {}
                        this.changeTopRadio()
                        this.changeTopRadio2()



                        this.changeBottomRadio()
                        this.changeBottomRadio2()

                    } else {
                        // this.MG_ElectricityPageData = {}
                        // this.$message.error('获取用电统计数据失败');
                    }
                }).catch(() => {
                    // this.MG_ElectricityPageDataLoading = false
                    // this.MG_ElectricityPageData = {}
                    // this.$message.error('获取用电统计数据失败');

                })

        },

        handleBack() {
            this.$emit("changePageblock", true);
        },
    },
};
</script>

<style scoped lang="scss">
.box {
    padding: 20px;
    box-sizing: border-box;
}

.dataStripe_title {
    display: block;
    line-height: 45px;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
}

// 数据条卡片内显示值
.dataStripe_value {
    display: block;
    line-height: 45px;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
}

.dataStripe_i {
    font-size: 40px;
    color: #ffffff;
    line-height: 90px;
    // margin-left: 20px;
}

.InfoCard {
    border-radius: 10px;
    padding: 0px 5px;
    box-sizing: border-box;
    border: 5px solid #c3c3c3;
}

::v-deep.ant-table-header {
    overflow-x: hidden !important;
}

::v-deep .el-card__body {
    padding: 10px;
}
</style>