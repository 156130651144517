<template>
    <div class="box" style="overflow: hidden;">
        <div style="display: flex;margin-bottom: 5px;">
            <div
                style="display: flex;align-items: center;justify-content: center;height: 30px;line-height: 30px;width: 40px;">
                <i size="30" class="el-icon-back" style="font-size: 23px;cursor: pointer;" @click="handleBack"></i>
            </div>
            <div style="font-size: 20px;font-weight: bold;line-height: 30px; margin-left: 20px;">数据分析-餐饮信息-用电统计</div>
        </div>


        <el-row :gutter="10" style="margin-top: 5px">
            <el-col :span="12" v-loading="MG_ElectricityPageDataLoading">
                <el-card style=" width: 100%;" class="InfoCard">
                    <el-row style="height: 35px;line-height: 35px;">
                        <el-col :span="16">
                            <span style="font-size: 18px; font-weight: bold" v-if="TopRadio === 1">不同业态商铺的用电负荷</span>
                            <span style="font-size: 18px; font-weight: bold"
                                v-if="TopRadio === 2">不同业态商铺不同版本的商铺数量</span>

                        </el-col>
                        <el-col :span="8" style="text-align: right;">
                            <el-radio-group v-model="TopRadio" size="small" @change="changeTopRadio">
                                <el-radio-button :label="1"><i class="el-icon-s-data"></i>&nbsp;用电负荷</el-radio-button>
                                <el-radio-button :label="2"><i class="el-icon-s-data"></i>&nbsp;商铺数量</el-radio-button>
                            </el-radio-group>

                        </el-col>
                    </el-row>
                    <el-row style="height: 1px;background-color: #EBEEF5;margin-top: 4px;"></el-row>
                    <el-row v-if="TopRadio == 1">
                        <EchartsIndex refId="optionTopDataBarAll" :option="optionTopDataBarAll"
                            styleStr="width: 100%;height: 290px;"
                            v-if="MG_ElectricityPageData.LoadQuota_Electricity_All && MG_ElectricityPageData.LoadQuota_Electricity_All.length > 0" />
                        <el-empty description="暂无数据" style="width: 100%; height: 290px;" v-else></el-empty>
                    </el-row>

                    <el-row v-if="TopRadio == 2">
                        <EchartsIndex refId="optionTopDataBarCount" :option="optionTopDataBarCount"
                            styleStr="width: 100%;height: 290px;"
                            v-if="MG_ElectricityPageData.LoadQuota_Electricity_Count && MG_ElectricityPageData.LoadQuota_Electricity_Count.length > 0" />
                        <el-empty description="暂无数据" style="width: 100%; height: 290px;" v-else></el-empty>
                    </el-row>


                </el-card>
            </el-col>
            <el-col :span="12" v-loading="MG_ElectricityPageDataLoading">
                <el-card style=" width: 100%" class="InfoCard">
                    <el-row style="height: 35px;line-height: 35px;">
                        <el-col :span="24">
                            <span style="font-size: 18px; font-weight: bold">不同业态商铺的用电负荷平均指标</span>

                        </el-col>

                    </el-row>
                    <el-row style="height: 1px;background-color: #EBEEF5;margin-top: 4px;"></el-row>
                    <el-row>
                        <EchartsIndex refId="optionTopDataBar2" :option="optionTopDataBar2"
                            styleStr="width: 100%;height: 290px;"
                            v-if="MG_ElectricityPageData.LoadQuota_Electricity_Avg && MG_ElectricityPageData.LoadQuota_Electricity_Avg.length > 0" />
                        <el-empty description="暂无数据" style="width: 100%; height: 290px;" v-else></el-empty>
                    </el-row>

                </el-card>
            </el-col>
        </el-row>
        <el-row :gutter="10" style="margin-top: 5px">
            <el-col :span="24" v-loading="MG_ElectricityPageDataLoading">
                <el-card style="width: 100%" class="InfoCard">
                    <el-row style="line-height: 35px;height: 35px;">
                        <el-col :span="16">
                            <span style="font-weight: bold;font-size: 18px;" v-if="BottomRadio === 1">
                                不同用电负荷指标商铺的商铺数量
                            </span>
                            <span style="font-weight: bold;font-size: 18px;" v-if="BottomRadio === 2">
                                不同用电负荷指标商铺的商铺数量占比
                            </span>

                        </el-col>
                        <el-col :span="8" style="text-align: right;">
                            <el-radio-group v-model="BottomRadio" size="small" @change="changeBottomRadio">

                                <el-radio-button :label="1"><i class="el-icon-s-data"></i>&nbsp;数量统计</el-radio-button>
                                <el-radio-button :label="2"><i class="el-icon-s-data"></i>&nbsp;占比统计</el-radio-button>

                            </el-radio-group>

                        </el-col>
                    </el-row>
                    <el-row style="height: 1px;background-color: #EBEEF5;margin-top: 4px;"></el-row>
                    <el-row v-if="BottomRadio == 1">
                        <EchartsIndex refId="optionBottomDataQuota_Count" :option="optionBottomDataQuota_Count"
                            styleStr="width: 100%;height: 290px;"
                            v-if="MG_ElectricityPageData.LoadQuota_Electricity_Quota_Count && MG_ElectricityPageData.LoadQuota_Electricity_Quota_Count.length > 0" />
                        <el-empty description="暂无数据" style="width: 100%; height: 290px;" v-else></el-empty>
                    </el-row>
                    <el-row v-if="BottomRadio == 2">
                        <EchartsIndex refId="optionBottomDataQuota_Proportion"
                            :option="optionBottomDataQuota_Proportion" styleStr="width: 100%;height: 290px;"
                            v-if="MG_ElectricityPageData.LoadQuota_Electricity_Quota_Proportion && MG_ElectricityPageData.LoadQuota_Electricity_Quota_Proportion.length > 0" />
                        <el-empty description="暂无数据" style="width: 100%; height: 290px;" v-else></el-empty>
                    </el-row>

                </el-card>
            </el-col>

        </el-row>
        <el-row :gutter="10" style="margin-top: 5px">

            <el-col :span="24" v-loading="MG_ElectricityPageDataLoading">
                <el-card style="width: 100%;" class="InfoCard">
                    <el-row style="font-weight: bold;font-size: 18px;height: 35px;line-height: 35px;">
                        <el-col :span="16">

                            <span v-if="BottomRadio2 === 1">不同面积区间的用电负荷量</span>
                            <span v-if="BottomRadio2 === 2">不同面积区间的用电负荷平均指标</span>
                            <span v-if="BottomRadio2 === 3">不同面积区间的商铺数量</span>
                        </el-col>
                        <el-col :span="8" style="text-align: right;">
                            <el-radio-group v-model="BottomRadio2" size="small" @change="changeBottomRadio2">

                                <el-radio-button :label="1"><i class="el-icon-s-data"></i>&nbsp;用电负荷</el-radio-button>
                                <el-radio-button :label="2"><i class="el-icon-s-data"></i>&nbsp;平均指标</el-radio-button>
                                <el-radio-button :label="3"><i class="el-icon-s-data"></i>&nbsp;商铺数量</el-radio-button>
                            </el-radio-group>

                        </el-col>
                    </el-row>
                    <el-row style="height: 1px;background-color: #EBEEF5;margin-top: 4px;"></el-row>

                    <el-row v-if="BottomRadio2 == 1">
                        <EchartsIndex refId="optionBottomDataAll" :option="optionBottomDataAll"
                            styleStr="width: 100%;height: 290px;"
                            v-if="MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_All && MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_All.length > 0" />
                        <el-empty description="暂无数据" style="width: 100%; height: 290px;" v-else></el-empty>
                    </el-row>

                    <el-row v-if="BottomRadio2 == 2">
                        <EchartsIndex refId="optionBottomDataAvg" :option="optionBottomDataAvg"
                            styleStr="width: 100%;height: 290px;"
                            v-if="MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_Avg && MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_Avg.length > 0" />
                        <el-empty description="暂无数据" style="width: 100%; height: 290px;" v-else></el-empty>
                    </el-row>
                    <el-row v-if="BottomRadio2 == 3">
                        <EchartsIndex refId="optionBottomDataCount" :option="optionBottomDataCount"
                            styleStr="width: 100%;height: 290px;"
                            v-if="MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_Count && MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_Count.length > 0" />
                        <el-empty description="暂无数据" style="width: 100%; height: 290px;" v-else></el-empty>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>

    </div>
</template>

<script>
// 引入echarts图表组件
import EchartsIndex from "@/components/Echarts/index";

export default {
    name: "MG_CateringDataPage", // 餐饮数据模块
    components: {
        EchartsIndex,
    },
    props: {
        typeList: {
            type: Array,
            default: () => [],
        },
        projectId: {
            type: String,
            default: "",
        },
        homeData: {
            type: Object,
            default: () => { },
        },
    },
    data() {
        return {
            TopRadio: 1,
            TopRadio2: 1,
            BottomRadio: 1,
            BottomRadio2: 1,
            MG_ElectricityPageData: {

            },
            MG_ElectricityPageDataLoading: false,

            optionTopDataBarAll: {
                grid: {
                    top: 50, // 设置图表距离容器顶部的距离为10%\
                    left: 120,
                    bottom: 30
                    // 其他grid属性...
                },
                label: {
                    show: true,
                    position: "top",
                    formatter: (val) => {

                        if (val.value) {
                            return val.value
                        } else {
                            return ''
                        }
                    }

                },
                legend: {
                    //图例系列
                    show: true,
                    top: 5,
                    itemWidth: 14,
                    itemHeight: 14,
                    data: [],
                },
                tooltip: {
                    trigger: "item",
                    formatter: `{a} : {c}kW`
                },
                xAxis: {

                    type: "category",
                    data: [],
                    axisLabel: {
                        // 坐标轴刻度标签的相关设置。
                        show: true,
                        interval: 0,
                        //rotate: "-45",
                    },
                },
                yAxis: {
                    axisLabel: {
                        formatter: "{value} kW",
                    },
                    name: "电负荷量（kW）",
                    nameLocation: "end",
                    nameTextStyle: {
                        width: 200,
                        align: "right",
                    },
                    axisLine: {
                        //坐标轴轴线
                        show: true,
                    },
                    axisTick: {
                        //坐标轴刻度
                        show: true,
                    },
                    type: "value",
                },
                series: [


                ],
            },
            optionTopDataBarCount: {
                grid: {
                    top: 50, // 设置图表距离容器顶部的距离为10%\
                    left: 120,
                    bottom: 30
                    // 其他grid属性...
                },
                label: {
                    show: true,
                    position: "top",
                    formatter: (val) => {

                        if (val.value) {
                            return val.value
                        } else {
                            return ''
                        }
                    }

                },
                legend: {
                    //图例系列
                    show: true,
                    top: 5,
                    itemWidth: 14,
                    itemHeight: 14,
                    data: [],
                },
                tooltip: {
                    trigger: "item",
                    formatter: `{a} : {c}个`
                },
                xAxis: {

                    type: "category",
                    data: [],
                    axisLabel: {
                        // 坐标轴刻度标签的相关设置。
                        show: true,
                        interval: 0,
                        //rotate: "-45",
                    },
                },
                yAxis: {
                    axisLabel: {
                        formatter: "{value} 个",
                    },
                    name: "商铺数量（个）",
                    nameLocation: "end",
                    nameTextStyle: {
                        width: 200,
                        align: "right",
                    },
                    axisLine: {
                        //坐标轴轴线
                        show: true,
                    },
                    axisTick: {
                        //坐标轴刻度
                        show: true,
                    },
                    type: "value",
                },
                series: [


                ],
            },
            optionTopDataBar2: {
                grid: {
                    top: 50, // 设置图表距离容器顶部的距离为10%\
                    left: 120,
                    bottom: 30
                    // 其他grid属性...
                },
                label: {
                    show: true,
                    position: "top",
                    formatter: (val) => {

                        if (val.value) {
                            return Number(val.value).toFixed(2)
                        } else {
                            return ''

                        }
                    },
                },

                legend: {
                    //图例系列
                    show: true,
                    top: 5,
                    itemWidth: 14,
                    itemHeight: 14,
                    data: [],
                },
                tooltip: {
                    trigger: "item",
                    formatter: (val) => {

                        return `${val.seriesName}：${Number(val.value).toFixed(2)}W/㎡`
                    }
                },
                xAxis: {

                    type: "category",
                    data: [],
                    axisLabel: {
                        // 坐标轴刻度标签的相关设置。
                        show: true,
                        interval: 0,
                        //rotate: "-45",
                    },
                },
                yAxis: {
                    axisLabel: {
                        formatter: "{value} W/㎡",
                    },
                    name: "平均指标（W/㎡）",
                    nameLocation: "end",
                    nameTextStyle: {
                        width: 200,
                        align: "right",
                    },
                    axisLine: {
                        //坐标轴轴线
                        show: true,
                    },
                    axisTick: {
                        //坐标轴刻度
                        show: true,
                    },
                    type: "value",
                },
                series: [


                ],
            },

            optionBottomDataQuota_Count: {
                grid: {
                    top: 60, // 设置图表距离容器顶部的距离为10%\
                    left: 120,
                    right: 70,
                    bottom: 30
                    // 其他grid属性...
                },
                label: {
                    show: true,
                    position: "top",
                    formatter: (val) => {

                        if (val.value) {
                            return val.value
                        } else {
                            return ''
                        }
                    }
                },
                legend: {
                    //图例系列
                    show: true,
                    top: 5,
                    itemWidth: 14,
                    itemHeight: 14,
                    data: [],
                },
                tooltip: {
                    trigger: "item",
                    formatter: `{a} : {c}个`
                },
                xAxis: {

                    type: "category",
                    data: [],
                    axisLabel: {
                        // 坐标轴刻度标签的相关设置。
                        show: true,
                        interval: 0,
                        // rotate: "-20",

                    },

                },
                yAxis: {
                    axisLabel: {
                        formatter: "{value} 个",
                    },
                    name: "商铺数量（个）",
                    nameLocation: "end",
                    nameTextStyle: {
                        width: 200,
                        align: "right",
                    },
                    axisLine: {
                        //坐标轴轴线
                        show: true,
                    },
                    axisTick: {
                        //坐标轴刻度
                        show: true,
                    },
                    type: "value",
                },
                series: [


                ],
            },
            optionBottomDataQuota_Proportion: {
                grid: {
                    top: 60, // 设置图表距离容器顶部的距离为10%\
                    left: 120,
                    right: 70,
                    bottom: 30
                    // 其他grid属性...
                },
                label: {
                    show: true,
                    position: "top",
                    formatter: (val) => {

                        if (val.value) {
                            return Number(val.value).toFixed(2) + '%'
                        } else {
                            return ''
                        }
                    }
                },
                legend: {
                    //图例系列
                    show: true,
                    top: 5,
                    itemWidth: 14,
                    itemHeight: 14,
                    data: [],
                },
                tooltip: {
                    trigger: "item",
                    formatter: (val) => {

                        return `${val.seriesName}：${Number(val.value).toFixed(2)}%`
                    }
                },
                xAxis: {

                    type: "category",
                    data: [],
                    axisLabel: {
                        // 坐标轴刻度标签的相关设置。
                        show: true,
                        interval: 0,
                        // rotate: "-20",

                    },

                },
                yAxis: {
                    axisLabel: {
                        formatter: "{value} %",
                    },
                    min: 0,
                    max: 100,

                    name: "占比统计（%）",
                    nameLocation: "end",
                    nameTextStyle: {
                        width: 200,
                        align: "right",
                    },
                    axisLine: {
                        //坐标轴轴线
                        show: true,
                    },
                    axisTick: {
                        //坐标轴刻度
                        show: true,
                    },
                    type: "value",
                },
                series: [


                ],
            },
            optionBottomDataCount: {
                grid: {
                    top: 60, // 设置图表距离容器顶部的距离为10%\
                    left: 120,
                    right: 70,
                    bottom: 30
                    // 其他grid属性...
                },
                label: {
                    show: true,
                    position: "top",
                    formatter: (val) => {

                        if (val.value) {
                            return val.value
                        } else {
                            return ''
                        }
                    }
                },
                legend: {
                    //图例系列
                    show: true,
                    top: 5,
                    itemWidth: 14,
                    itemHeight: 14,
                    data: [],
                },
                tooltip: {
                    trigger: "item",
                    formatter: `{a} : {c}个`
                },
                xAxis: {

                    type: "category",
                    data: [],
                    axisLabel: {
                        // 坐标轴刻度标签的相关设置。
                        show: true,
                        interval: 0,
                        //rotate: "-45",
                    },
                },
                yAxis: {
                    axisLabel: {
                        formatter: "{value} 个",
                    },
                    name: "商铺数量（个）",
                    nameLocation: "end",
                    nameTextStyle: {
                        width: 200,
                        align: "right",
                    },
                    axisLine: {
                        //坐标轴轴线
                        show: true,
                    },
                    axisTick: {
                        //坐标轴刻度
                        show: true,
                    },
                    type: "value",
                },
                series: [


                ],
            },
            optionBottomDataAll: {
                grid: {
                    top: 60, // 设置图表距离容器顶部的距离为10%\
                    left: 120,
                    right: 70,
                    bottom: 30
                    // 其他grid属性...
                },
                label: {
                    show: true,
                    position: "top",
                    formatter: (val) => {

                        if (val.value) {
                            return val.value
                        } else {
                            return ''
                        }
                    }
                },
                legend: {
                    //图例系列
                    show: true,
                    top: 5,
                    itemWidth: 14,
                    itemHeight: 14,
                    data: [],
                },
                tooltip: {
                    trigger: "item",
                    formatter: `{a} : {c}kW`
                },
                xAxis: {

                    type: "category",
                    data: [],
                    axisLabel: {
                        // 坐标轴刻度标签的相关设置。
                        show: true,
                        interval: 0,
                        //rotate: "-45",
                    },
                },
                yAxis: {
                    axisLabel: {
                        formatter: "{value} kW",
                    },
                    name: "电负荷量（kW）",
                    nameLocation: "end",
                    nameTextStyle: {
                        width: 200,
                        align: "right",
                    },
                    axisLine: {
                        //坐标轴轴线
                        show: true,
                    },
                    axisTick: {
                        //坐标轴刻度
                        show: true,
                    },
                    type: "value",
                },
                series: [


                ],
            },
            optionBottomDataAvg: {
                grid: {
                    top: 60, // 设置图表距离容器顶部的距离为10%\
                    left: 120,
                    right: 70,
                    bottom: 30
                    // 其他grid属性...
                },
                label: {
                    show: true,
                    position: "top",
                    formatter: (val) => {

                        if (val.value) {
                            return Number(val.value).toFixed(2)
                        } else {
                            return ''
                        }
                    }
                },
                legend: {
                    //图例系列
                    show: true,
                    top: 5,
                    itemWidth: 14,
                    itemHeight: 14,
                    data: [],
                },
                tooltip: {
                    trigger: "item",
                    formatter: (val) => {
                        return `${val.name}：${Number(val.value).toFixed(2)}W/㎡`
                    }
                },
                xAxis: {

                    type: "category",
                    data: [],
                    axisLabel: {
                        // 坐标轴刻度标签的相关设置。
                        show: true,
                        interval: 0,
                        //rotate: "-45",
                    },
                },
                yAxis: {
                    axisLabel: {
                        formatter: "{value} W/㎡",
                    },
                    name: "平均指标（W/㎡）",
                    nameLocation: "end",
                    nameTextStyle: {
                        width: 200,
                        align: "right",
                    },
                    axisLine: {
                        //坐标轴轴线
                        show: true,
                    },
                    axisTick: {
                        //坐标轴刻度
                        show: true,
                    },
                    type: "value",
                },
                series: [


                ],
            },

        };
    },
    mounted() {

    },
    created() {


        this.getMG_ElectricityPageData();
    },
    methods: {
        changeBottomRadio() {
            if (this.MG_ElectricityPageData.LoadQuota_Electricity_Quota_Count && this.MG_ElectricityPageData.LoadQuota_Electricity_Quota_Count.length > 0) {
                this.optionBottomDataQuota_Count.legend.data = []
                this.optionBottomDataQuota_Count.series = []
                this.optionBottomDataQuota_Count.xAxis.data = []
                this.MG_ElectricityPageData.LoadQuota_Electricity_Quota_Count.forEach((item, index) => {
                    if (index === 0) {
                        item.value.forEach(val => {
                            this.optionBottomDataQuota_Count.xAxis.data.push(val.name)
                        })

                    }
                    this.optionBottomDataQuota_Count.legend.data.push(item.name);
                    this.optionBottomDataQuota_Count.series.push({
                        name: item.name,
                        data: item.value,
                        type: "bar",

                        showBackground: true,
                        barGap: '100%',
                        emphasis: {
                            focus: "series",
                        },
                        selectedMode: 'series',
                        // barWidth: 20,

                    })
                })
            }

            if (this.MG_ElectricityPageData.LoadQuota_Electricity_Quota_Proportion && this.MG_ElectricityPageData.LoadQuota_Electricity_Quota_Proportion.length > 0) {
                this.optionBottomDataQuota_Proportion.legend.data = []
                this.optionBottomDataQuota_Proportion.series = []
                this.optionBottomDataQuota_Proportion.xAxis.data = []
                this.MG_ElectricityPageData.LoadQuota_Electricity_Quota_Proportion.forEach((item, index) => {
                    if (index === 0) {
                        item.value.forEach(val => {
                            this.optionBottomDataQuota_Proportion.xAxis.data.push(val.name)
                        })

                    }
                    this.optionBottomDataQuota_Proportion.legend.data.push(item.name);
                    this.optionBottomDataQuota_Proportion.series.push({
                        name: item.name,
                        data: item.value,
                        type: "bar",

                        showBackground: true,
                        barGap: '100%',
                        emphasis: {
                            focus: "series",
                        },
                        selectedMode: 'series',
                        // barWidth: 20,

                    })
                })
            }

        },
        changeBottomRadio2() {
            if (this.BottomRadio2 === 1) {
                if (this.MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_All && this.MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_All.length > 0) {
                    this.optionBottomDataAll.legend.data = []
                    this.optionBottomDataAll.series = []
                    this.optionBottomDataAll.xAxis.data = []
                    this.MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_All.forEach((item, index) => {
                        if (index === 0) {
                            item.value.forEach(val => {
                                this.optionBottomDataAll.xAxis.data.push(val.name)
                            })

                        }
                        this.optionBottomDataAll.legend.data.push(item.name);
                        this.optionBottomDataAll.series.push({
                            name: item.name,
                            data: item.value,
                            type: "bar",

                            showBackground: true,
                            barGap: '100%',
                            emphasis: {
                                focus: "series",
                            },
                            selectedMode: 'series',
                            // barWidth: 20,

                        })
                    })
                }
            } else if (this.BottomRadio2 === 2) {
                if (this.MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_Avg && this.MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_Avg.length > 0) {
                    this.optionBottomDataAvg.legend.data = []
                    this.optionBottomDataAvg.series = []
                    this.optionBottomDataAvg.xAxis.data = []
                    this.MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_Avg.forEach((item, index) => {
                        if (index === 0) {
                            item.value.forEach(val => {
                                this.optionBottomDataAvg.xAxis.data.push(val.name)
                            })

                        }
                        this.optionBottomDataAvg.legend.data.push(item.name);

                        this.optionBottomDataAvg.series.push({
                            name: item.name,
                            data: item.value,
                            type: "bar",
                            stack: item.name,
                            showBackground: true,
                            barGap: '100%',
                            emphasis: {
                                focus: "series",
                            },
                            selectedMode: 'series',
                            // barWidth: 20,

                        })
                    })
                }
            } else if (this.BottomRadio2 === 3) {
                if (this.MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_Count && this.MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_Count.length > 0) {
                    this.optionBottomDataCount.legend.data = []
                    this.optionBottomDataCount.series = []
                    this.optionBottomDataCount.xAxis.data = []
                    this.MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_Count.forEach((item, index) => {
                        if (index === 0) {
                            item.value.forEach(val => {
                                this.optionBottomDataCount.xAxis.data.push(val.name)
                            })

                        }
                        this.optionBottomDataCount.legend.data.push(item.name);

                        this.optionBottomDataCount.series.push({
                            name: item.name,
                            data: item.value,
                            type: "bar",
                            stack: item.name,
                            showBackground: true,
                            barGap: '100%',
                            emphasis: {
                                focus: "series",
                            },
                            selectedMode: 'series',
                            // barWidth: 20,

                        })
                    })
                }
            }

        },
        changeTopRadio() {
            if (this.TopRadio === 1) {
                if (this.MG_ElectricityPageData.LoadQuota_Electricity_All && this.MG_ElectricityPageData.LoadQuota_Electricity_All.length > 0) {
                    this.optionTopDataBarAll.legend.data = []
                    this.optionTopDataBarAll.series = []
                    this.optionTopDataBarAll.xAxis.data = []
                    this.MG_ElectricityPageData.LoadQuota_Electricity_All.forEach((item, index) => {
                        if (index === 0) {
                            item.value.forEach(val => {
                                this.optionTopDataBarAll.xAxis.data.push(val.name)
                            })

                        }
                        this.optionTopDataBarAll.legend.data.push(item.name);
                        this.optionTopDataBarAll.series.push({
                            name: item.name,
                            data: item.value,
                            type: "bar",

                            showBackground: true,
                            barGap: '100%',
                            emphasis: {
                                focus: "series",
                            },
                            selectedMode: 'series',
                            // barWidth: 20,

                        })
                    })
                }
            } else if (this.TopRadio === 2) {
                if (this.MG_ElectricityPageData.LoadQuota_Electricity_Count && this.MG_ElectricityPageData.LoadQuota_Electricity_Count.length > 0) {
                    this.optionTopDataBarCount.legend.data = []
                    this.optionTopDataBarCount.series = []
                    this.optionTopDataBarCount.xAxis.data = []
                    this.MG_ElectricityPageData.LoadQuota_Electricity_Count.forEach((item, index) => {
                        if (index === 0) {
                            item.value.forEach(val => {
                                this.optionTopDataBarCount.xAxis.data.push(val.name)
                            })

                        }
                        this.optionTopDataBarCount.legend.data.push(item.name);
                        this.optionTopDataBarCount.series.push({
                            name: item.name,
                            data: item.value,
                            type: "bar",

                            showBackground: true,
                            barGap: '100%',
                            emphasis: {
                                focus: "series",
                            },
                            selectedMode: 'series',
                            // barWidth: 20,

                        })
                    })
                }
            }

        },
        changeTopRadio2() {
            if (this.MG_ElectricityPageData.LoadQuota_Electricity_Avg && this.MG_ElectricityPageData.LoadQuota_Electricity_Avg.length > 0) {
                this.optionTopDataBar2.legend.data = []
                this.optionTopDataBar2.series = []
                this.optionTopDataBar2.xAxis.data = []
                this.MG_ElectricityPageData.LoadQuota_Electricity_Avg.forEach((item, index) => {
                    if (index === 0) {
                        item.value.forEach(val => {
                            this.optionTopDataBar2.xAxis.data.push(val.name)
                        })

                    }
                    this.optionTopDataBar2.legend.data.push(item.name);

                    this.optionTopDataBar2.series.push({
                        name: item.name,
                        data: item.value,
                        type: "bar",

                        showBackground: true,
                        barGap: '100%',
                        emphasis: {
                            focus: "series",
                        },
                        selectedMode: 'series',
                        // barWidth: 20,

                    })
                })
            }
        },
        getMG_ElectricityPageData() {
            this.MG_ElectricityPageDataLoading = true
            this.$http
                .post("/Base_ProjectManage/Project_DataAnalysis/GetMG_CateringReturnData_HR?projectId=" + this.projectId).then(res => {
                    this.MG_ElectricityPageDataLoading = false
                    if (res.Success) {
                        this.MG_ElectricityPageData = res.Data || {}
                        this.changeTopRadio()
                        this.changeTopRadio2()



                        this.changeBottomRadio()
                        this.changeBottomRadio2()

                    } else {
                        this.MG_ElectricityPageData = {}
                        this.$message.error('获取用电统计数据失败');
                    }
                }).catch(() => {
                    this.MG_ElectricityPageDataLoading = false
                    this.MG_ElectricityPageData = {}
                    this.$message.error('获取用电统计数据失败');

                })

        },

        handleBack() {
            this.$emit("changePageblock", true);
        },
    },
};
</script>

<style scoped lang="scss">
.box {
    padding: 0px;
    box-sizing: border-box;
}

.dataStripe_title {
    display: block;
    line-height: 45px;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
}

// 数据条卡片内显示值
.dataStripe_value {
    display: block;
    line-height: 45px;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
}

.dataStripe_i {
    font-size: 40px;
    color: #ffffff;
    line-height: 90px;
    // margin-left: 20px;
}

.InfoCard {
    border-radius: 10px;
    padding: 0px 5px;
    box-sizing: border-box;
    border: 5px solid #c3c3c3;
}

::v-deep.ant-table-header {
    overflow-x: hidden !important;
}
</style>